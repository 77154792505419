<template>
  <div v-if="question?.interface === 'text'" class="flex flex-col">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? 'text-error-01 ' : ' text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>
    <div
        v-else
        :class="error ? 'text-error-01 ' : ' text-bw-30'"
        class="text-lg leading-6 font-[550] border-b border-bw-31 pb-2 mb-3"
    >
      {{ question?.content }}
    </div>
    <input
        :type="question?.interface"
        class="px-5 py-3.5 border rounded-3 text-sm leading-6 font-medium capitalize h-13.5 flex items-center w-full"
        :placeholder="question?.content + '...'"
        v-model="data"
        @blur="checkValue"
        :class="error ? 'text-error-01 border-error-01 placeholder:text-error-01' : 'border-#CBCBCB text-bw-32 '"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String
})

const emits = defineEmits(['getData'])

const error = ref(false)
const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})

const data = ref(answer.value)

const checkValue = () => {
  if (data.value) {
    error.value = false
    emits('getData', data.value)
  } else {
    error.value = true
  }
}

// const setValue = (value: any) => {}

watch(
    () => [data.value, props.error],
    () => {
      checkValue()
    },
    {
      deep: true
    }
)
</script>

<style></style>
