<template>
  <div v-if="question?.size == 'large' && question?.interface == 'checkbox'">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? 'text-error-01 ' : ' text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>
    <div
        v-else
        class="text-bw-30 text-lg font-[550] leading-6 pb-2 mb-3 border-b border-bw-31"
        :class="error ? '!text-error-01' : ''"
    >
      {{ question?.content }}
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <label
          v-for="ans in question?.answers"
          :key="ans?.id"
          :for="'analysis-' + ans?.id"
          class="p-4 rounded-3 bg-final-05 text-bw-30 text-sm leading-5 font-medium min-h-35 flex flex-col justify-between group bg-gradient-radio duration-300 overflow-hidden cursor-pointer box-input-analysis checkbox-custom"
          :class="[handleUpdateChecked(ans?.id) ? 'isChecked' : '']"
      >
        <div class="flex justify-between items-center">
                    <span class="w-9 h-9">
                        <img v-if="ans?.icon" :src="getThumbnail(ans?.icon, 100)" alt="icon" class="w-9 h-9 icon"/>
                    </span>
          <input
              :type="question?.interface"
              class="analysis"
              :id="'analysis-' + ans?.id"
              :value="ans?.id"
              v-model="data"
          />
        </div>

        <div
            :class="[
                        handleUpdateChecked(ans?.id) ? '!text-white' : '',
                        error ? 'text-error-01 ' : ' text-bw-30'
                    ]"
            class="group-hover:text-white flex-1 flex items-end font-semibold"
        >
          {{ ans?.content }}
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String
})

const emits = defineEmits(['getData'])

const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})
const data = ref<Object[]>(answer.value != null ? answer.value : [])
const error = ref(false)

const handleUpdateChecked = (value) => {
  if (data.value && data.value.length > 0) {
    let result = data.value.some((item) => item === +value)
    return result
  }
}

const checkValue = () => {
  if (data.value && data.value.length > 0) {
    error.value = false
    emits('getData', data.value)
  } else {
    error.value = true
  }
}

watch(
    () => data.value,
    (newValue) => {
      if (newValue != null && newValue.length > 0) {
        checkValue()
      }
    }
)
watch(
    () => props.error,
    () => {
      checkValue()
    }
)

watch(
    () => props.answer,
    (newValue) => {
      if (Object.keys(newValue).length < 1) {
        data.value = []
      }
    }
)
</script>

<style>
.checkbox-custom input[type='checkbox'].analysis {
  border: var(--borderWidth) solid rgba(242, 101, 46, 0.5);
}

.box-input-analysis:hover input[type='checkbox'].analysis,
.checkbox-custom input[type='checkbox'].analysis:checked {
  border: var(--borderWidth) solid white;
}

.box-input-analysis.isChecked img.icon,
.box-input-analysis:hover img.icon {
  filter: brightness(0) invert(1);
}

.box-input-analysis.isChecked p {
  color: white;
}

.titleForm.error h2 span {
  @apply !text-error-01;
}
</style>
