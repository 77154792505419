<template>
  <div v-if="question?.interface == 'radio' && question?.size !== 'large'" class="flex flex-col">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? 'text-error-01 ' : ' text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>

    <div
        v-else
        class="text-bw-30 text-lg font-[550] leading-6 pb-2 mb-3 border-b border-bw-31"
        :class="error ? '!text-error-01' : ''"
    >
      {{ question?.content }}
    </div>

    <ul :class="question?.layout == 'row' ? 'flex gap-4 w-full flex-wrap' : 'flex flex-col'">
      <li v-for="ans in question?.answers" :key="ans?.id">
        <label
            :for="'ans_' + question?.id + '_' + ans?.id"
            class="flex gap-2 cursor-pointer"
            :class="question?.layout == 'row' ? 'items-center' : 'py-2'"
            @change="checkValue()"
        >
          <div class="pt-1">
            <input
                type="radio"
                :name="'ans_' + question?.id"
                :id="'ans_' + question?.id + '_' + ans?.id"
                :value="ans?.id"
                class="radio-custom"
                v-model="data"
            />
          </div>
          <div class="flex-1 leading-6 text-sm font-medium" :class="error ? 'text-error-01 ' : ' text-bw-30'">
            {{ ans?.content }}
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String,
  type: String
})

const emits = defineEmits(['getData'])

const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})

const data = ref(answer.value)
const error = ref(false)

const checkValue = () => {
  if (data.value) {
    error.value = false
    emits('getData', data.value)
  } else {
    error.value = true
  }
}
watch(
    () => [data.value, props.error],
    () => {
      checkValue()
    },
    {
      deep: true
    }
)
</script>

<style></style>
