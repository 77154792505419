<template>
  <div v-if="question?.size == 'large' && question?.interface == 'radio'" class="w-full">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? 'text-error-01 ' : ' text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>
    <div
        v-else
        :class="error ? 'text-error-01 ' : ' text-bw-30'"
        class="text-lg leading-6 font-[550] border-b border-bw-31 pb-2 mb-3"
    >
      {{ question?.content }}
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <label
          v-for="ans in question?.answers"
          :for="'analysis-' + ans?.id"
          class="p-4 rounded-3 box-item-radio bg-final-05 text-bw-30 text-sm leading-5 font-semibold min-h-35 flex flex-col justify-between group bg-gradient-radio duration-300 overflow-hidden cursor-pointer box-input-analysis"
          :class="data == ans?.id ? 'isChecked' : ''"
      >
        <div class="flex justify-between items-center">
                    <span class="w-9 h-9 text-primary group-hover:text-white">
                        <img v-if="ans?.icon" :src="getThumbnail(ans?.icon, 100)" alt="icon" class="w-9 h-9 icon"/>
                    </span>

          <input
              :type="question?.interface"
              class="radido-custom-2"
              name="stageOfLife"
              :id="'analysis-' + ans?.id"
              :value="ans?.id"
              v-model="data"
              @click=";[(data = ans?.id), checkValue]"
          />
        </div>

        <div
            :class="[data == ans?.id ? '!text-white' : '', error ? 'text-error-01 ' : ' text-bw-30']"
            class="group-hover:text-white flex-1 flex items-end"
        >
          {{ ans?.content }}
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String
})

const emits = defineEmits(['getData'])

const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})

const data = ref(answer.value)
const error = ref(false)

const checkValue = () => {
  if (data.value) {
    error.value = false
    emits('getData', data.value)
  } else {
    error.value = true
  }
}

watch(
    () => data.value,
    (newValue) => {
      if (newValue != null) {
        checkValue()
      }
    }
)
watch(
    () => props.error,
    () => {
      checkValue()
    }
)

watch(
    () => props.answer,
    (newValue) => {
      if (Object.keys(newValue).length < 1) {
        data.value = null
      }
    }
)
</script>

<style></style>
