<template>
  <ul class="flex gap-2 items-center mb-5 xl:mb-11 flex-wrap" v-if="dataRender">
    <item-breadcrumb :step="step" :dataRender="dataRender" :indexActive="indexActive">
      <template v-if="dataRender?.children.length > 0"></template>
    </item-breadcrumb>
  </ul>
</template>

<script setup lang="ts">
import ItemBreadcrumb from './__ItemBreadcrumb.vue'

defineProps({
  indexActive: {
    type: Number
  },
  dataRender: {
    type: Object as any
  }
})

const step = ref(1)
</script>

<style></style>
