<template>
  <section class="relative container py-10 flex lg:flex-row flex-col lg:gap-15 xl:gap-24.5">
    <div class="flex flex-col lg:max-w-100 xl:max-w-133.5 w-full lg:h-full lg:sticky lg:top-35">
      <breadcrumb :indexActive="step" :dataRender="res"></breadcrumb>
      <div v-if="dataRender?.title" class="post-content" v-html="dataRender?.title"></div>
      <div
          class="text-bw-27 text-base leading-7 lg:max-w-117 post-content"
          v-html="dataRender?.description"
      ></div>
      <div class="absolute top-full -left-50 lg:block hidden">
        <div class="w-180 xl:w-220 aspect-260/122">
          <img src="/images/global/analysis2.png" alt="background" class="w-full h-full object-contain"/>
        </div>
        <div class="lg:w-50 xl:w-70 aspect-65/49 absolute top-[20%] left-1/2">
          <img
              src="/images/global/masgotAnalysis.png"
              alt="masgot"
              class="w-full h-full object-contain animationSway"
          />
        </div>
      </div>
    </div>

    <div class="relative absolute bottom-0 left-0 lg:hidden block flex justify-center">
      <div class="w-138 aspect-238/147">
        <img src="/images/global/analysis2.png" alt="background" class="w-full h-full object-contain"/>
      </div>
      <div class="w-35 md:w-40 aspect-65/49 absolute top-[20%] left-1/2">
        <img
            src="/images/global/masgotAnalysis.png"
            alt="masgot"
            class="w-full h-full object-contain animationSway"
        />
      </div>
    </div>

    <div class="flex-1 flex flex-col">
      <div
          class="flex flex-col gap-6"
          :class="recommendData.products && recommendData.products.length > 0 ? 'mb-6' : ''"
      >
        <template v-for="item in recommendData.products" :key="item?.id">
          <box-recommend :data="item"></box-recommend>
        </template>
      </div>

      <div class="relative bg-#FFF5ED rounded-5 overflow-hidden p-7">
        <img
            src="/images/circle/hinh18.svg"
            alt="cricle"
            class="absolute inset-0 -z-1 w-full h-full object-cover"
        />
        <v-text
            tag="h2"
            class="text-#404040 text-xl leading-7 font-[550] mb-2"
            option_key="analysisRecommend?.title"
        ></v-text>
        <v-text class="text-#4B4B4B text-15px leading-5 mb-12" option_key="analysisRecommend?.intro"></v-text>

        <div class="flex md:gap-2 gap-3 md:flex-row flex-col w-full">
          <button-primary
              :title="$t('REQUEST_CALL_BACK')"
              @click="requestCallBack()"
              class="md:w-max w-full rounded-full text-xs !font-[550] max-h-10"
              pading="py-2.5 px-5"
          ></button-primary>
          <button-primary
              :title="$t('CALL_US_NOW')"
              :url="`tel:${links_global?.hotline}`"
              class="!md:w-max !w-full rounded-full text-xs !font-[550] max-h-10"
              pading="py-2.5 px-5"
              option
          ></button-primary>
        </div>
      </div>
    </div>
  </section>
  <popup v-if="isPopup" :products="recommendData.products" @closeOTP="isPopup = $events"></popup>
</template>

<script lang="ts">
export default {
  name: 'recommend'
}
</script>

<script setup lang="ts">
import BoxRecommend from './__BoxRecommend.vue'
import Popup from './__PopupRequest.vue'
import Breadcrumb from './__Breadcrumb.vue'

const links_global = inject('globalSettings')?.links[0]
const {getAnalysisGroups} = useAnalysis()
const {actionFilterProductsAnalysis} = useProducts()

const props = defineProps({
  step: {
    type: Number
  }
})

const isPopup = ref(false)
const res = await getAnalysisGroups(useRoute().params.langCode).then((response) => response?.analysis_group)

const dataRender = computed(() => {
  return res?.children[0]?.children[0]?.children[0]
})

const data_input = ref<any>(getStorage('data_analysis', {}) ?? {})
const main_data = ref<any>(data_input.value?.MainData ?? {})
const data_submit = ref({})
for (const key in main_data.value) {
  if (main_data.value.hasOwnProperty(key)) {
    const value = main_data.value[key]
    if (typeof value !== 'string') {
      data_submit.value[key] = value
    }
  }
}

const recommendData = ref()
// console.log(`data submit`, data_submit.value)
recommendData.value = await actionFilterProductsAnalysis(
    useRoute().params.langCode,
    {list: data_submit.value},
    {}
).then((response) => response)

// console.log(`output->recommendData.value`, recommendData.value)

const requestCallBack = () => {
  isPopup.value = true
}
</script>

<style></style>
