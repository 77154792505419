<template>
  <v-popup overlay closeOverlay onOpenLoadPage keyPopup="popupResultOtp" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="$emit('update:visible', false)"
            ></span>
      <div class="">
        <h5 class="text-32px font-[550] leading-10 text-center text-primary mb-3 text-center">
          {{ t('DO_YOU_WANT_TO_CONTINUE') }}
        </h5>
      </div>
    </template>
    <div class="flex justify-center pt-3 pb-7.5">
      <div class="w-50 aspect-40/30">
        <img class="w-full h-full object-contain animationSway" src="/images/brand-iz4-3.png" alt="masgot"/>
      </div>
    </div>
    <template #footer>
      <div class="flex gap-2.5">
        <button
            class="rounded-9 overflow-hidden border border-primary text-primary uppercase font-[550] text-xs leading-5 py-2.5 px-5 hover:text-white hover:bg-primary duration-300 h-13.5 flex items-center text-center"
            @click="handleRestart()"
        >
          {{ t('RESTART') }}
        </button>
        <button-primary
            :title="t('CONTINUE')"
            class="flex-1 rounded-full !font-[550] h-13.5 flex items-center"
            padding="py-2.5 px-5"
            @click="handleContinue()"
        ></button-primary>
      </div>
    </template>
  </v-popup>
</template>

<script lang="ts">
export default {
  name: 'popup_analysis'
}
</script>

<script setup lang="ts">
import nuxtStorage from 'nuxt-storage'

const {t} = useI18n()

const emits = defineEmits(['update:visible', 'isRestart', 'isContinue'])

const handleContinue = () => {
  if (getStorage('data_analysis', {})?.MainData) {
    emits('update:visible', false)
    emits('isContinue', true)
  }
}

const handleRestart = () => {
  if (getStorage('data_analysis', {})?.MainData) {
    nuxtStorage.localStorage.removeItem('data_analysis')
    emits('update:visible', false)
    emits('isRestart', true)
  }
}
</script>

<style></style>
