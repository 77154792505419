<template>
  <div v-if="question?.interface == 'checkbox' && question?.size !== 'large'">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? 'text-error-01 ' : ' text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>
    <div
        v-else
        class="text-bw-30 text-lg font-[550] leading-6 pb-2 mb-3 border-b border-bw-31"
        :class="error ? '!text-error-01' : ''"
    >
      {{ question?.content }}
    </div>

    <ul :class="question?.layout == 'row' ? 'flex gap-4 w-full flex-wrap' : 'flex flex-col'">
      <li v-for="ans in question?.answers" :key="ans?.id">
        <label
            :for="'analysis-' + ans?.id"
            class="flex gap-2 cursor-pointer items-center py-2"
            :class="[handleUpdateChecked(ans?.id) ? 'isChecked' : '']"
        >
          <div class="flex justify-between items-center checkbox-custom">
            <input
                :class="handleUpdateChecked(ans?.id) ? '' : ''"
                :type="question?.interface"
                class="analysis"
                :id="'analysis-' + ans?.id"
                :value="ans?.id"
                v-model="data"
            />
          </div>

          <div
              :class="[error ? 'text-error-01 ' : ' text-bw-30']"
              class="flex-1 leading-6 text-sm font-medium"
          >
            {{ ans?.content }}
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String
})

const emits = defineEmits(['getData'])

const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})
const data = ref<Object[]>(answer.value != null ? answer.value : [])
const error = ref(false)

const handleUpdateChecked = (value) => {
  if (data.value && data.value.length > 0) {
    let result = data.value.some((item) => item === +value)
    return result
  }
}

const checkValue = () => {
  if (data.value && data.value.length > 0) {
    error.value = false
    emits('getData', data.value)
  } else {
    error.value = true
  }
}

watch(
    () => data.value,
    (newValue) => {
      if (newValue != null && newValue.length > 0) {
        checkValue()
      }
    }
)
watch(
    () => props.error,
    () => {
      checkValue()
    }
)

watch(
    () => props.answer,
    (newValue) => {
      if (Object.keys(newValue).length < 1) {
        data.value = []
      }
    }
)
</script>

<style scoped>
.checkbox-custom input[type='checkbox'].analysis {
  border: var(--borderWidth) solid rgba(242, 101, 46, 0.5);
}

.box-input-analysis:hover input[type='checkbox'].analysis,
.checkbox-custom input[type='checkbox'].analysis:checked {
  border: var(--borderWidth) solid #ff8050;
  background-color: #ff8050;
}

.checkbox-custom input[type='checkbox'].analysis:checked:after,
.checkbox-custom input[type='checkbox'].analysis:checked:before {
  background: white;
}

.box-input-analysis.isChecked img.icon,
.box-input-analysis:hover img.icon {
  filter: brightness(0) invert(1);
}

.box-input-analysis.isChecked p {
  color: white;
}

.titleForm.error h2 span {
  @apply !text-error-01;
}
</style>
