<template>
  <div class="rounded-15px overflow-hidden bg-final-02 p-5 flex gap-7.5 md:flex-row flex-col">
    <div class="relative">
      <!-- <div class="absolute top-5 left-0 bg-#FA532B text-xs font-medium px-4 py-2 text-white rounded-r-full">
          Essential
      </div> -->
      <img
          :src="getThumbnail(data?.thumbnail?.id, 800)"
          class="md:w-54 aspect-4/3 md:aspect-54/71 h-full w-full object-cover rounded-15px"
          :alt="data?.thumbnail?.description || data?.thumbnail?.title"
      />
    </div>
    <div class="flex flex-col flex-1">
      <h2 class="text-accent-03 text-22px leading-7 font-[550] block">{{ data?.title }}</h2>
      <p class="text-#777 text-13px leading-6 mb-3">{{ data?.intro }}</p>
      <ul class="flex flex-col border-t border-b border-accent-03/11 divide-y divide-accent-03/11 mb-4.5">
        <template v-for="item in dataBenefits" :key="item?.id">
          <li v-if="item?.sum_insured > 0" class="flex justify-between gap-5 py-2 pr-2">
            <span class="text-#777 text-xs leading-5 font-semibold flex-1">{{ item?.title }}</span>
            <span class="text-#333 text-sm leading-5 font-semibold min-w-25 flex justify-end">
                            {{ parseFloat(item?.sum_insured).toLocaleString('en-US') + ' đ' }}
                        </span>
          </li>
        </template>
      </ul>
      <div
          class="flex md:flex-row lg:flex-col xl:flex-row flex-col gap-1.5 md:justify-start justify-center mt-auto"
      >
        <button-primary
            :title="$t('APPLY')"
            class="md:w-max lg:w-full xl:w-max w-full rounded-full text-xs !font-[550] max-h-10 md:max-h-none"
            :url="data?.onelink_url"
            padding="py-3 px-8"
        ></button-primary>
        <nuxt-link
            :to="data?.url"
            class="rounded-9 overflow-hidden border border-primary text-primary uppercase font-[550] text-xs leading-5 py-10px md:py-3 px-8 hover:text-white hover:bg-primary duration-300 text-center max-h-10 md:max-h-none"
        >
          {{ $t('SEE_MORE') }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object
  }
})

const dataBenefits = computed(() => {
  return props.data?.benefits.slice(0, 4)
})
</script>

<style></style>
