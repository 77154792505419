<template>
  <v-popup v-model:visible="isOpen" overlay closeOverlay onOpenLoadPage keyPopup="popupRequest" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="closeOTP()"
            ></span>
      <div class="w-full">
        <h5 class="text-3xl leading-44px lg:text-32px !font-[550] leading-10 text-center mb-3 text-center gap-1">
          {{ $t('NEED') }}
          <span class="text-primary">{{ $t('CONSULTANE') }}</span>
        </h5>
      </div>
    </template>
    <form @submit.prevent="onSubmit" id="form-request" class="form-submit mt-4 w-full pr-2 md:pr-0">
      <div class="flex flex-col gap-y-3 lg:gap-y-5">
        <div class="w-full">
          <label
              for="name_popup_request_analysis"
              :class="errorName ? '!text-red-500' : ''"
              class="uppercase text-bw-26 text-13px font-semibold block pb-2"
          >{{ $t('FIELD_NAME') }}*</label
          >
          <input
              id="name_popup_request_analysis"
              name="name"
              type="text"
              :class="errorName ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 focus:ring-0 b-1 b-solid b-[#CBCBCB] capitalize h-13.5 flex items-center"
              :placeholder="$t('INPUT_NAME')"
              @blur="checkName"
              v-model="infoForm.name"
          />
          <Transition name="fade">
                        <span class="text-red-500 pl-5 mt-2" v-if="errorName">
                            {{ $t('PLEASE_ENTER_YOUR_NAME') }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label
              for="phone_popup_request_analysis"
              :class="errorPhone ? '!text-red-500' : ''"
              class="uppercase text-bw-26 text-13px font-semibold block pb-2"
          >{{ $t('FIELD_PHONE') }}*</label
          >
          <input
              id="phone_popup_request_analysis"
              name="phone"
              type="text"
              :class="errorPhone ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
              :placeholder="$t('INPUT_PHONE')"
              @input="convertedPhoneNumber"
              @blur="checkPhone"
              v-model="infoForm.phone"
          />
          <Transition name="fade">
                        <span class="text-red-500 pl-5 mt-2" v-if="errorPhone">
                            {{ $t('INCORRECT_PHONE_NUMBER') }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label
              for="product_name__popup_request_analysis"
              class="uppercase text-bw-26 text-13px font-semibold block pb-2"
          >
            {{ $t('INTERESTED_PRODUCT') }}
          </label>
          <div class="relative flex items-center group">
            <select
                id="product_category_popup_request_analysis"
                required
                class="w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
                :class="checkOneProduct ? 'cursor-not-allowed !text-gray-400 bg-bw-04/20' : 'bg-white'"
                v-model="infoForm.product"
                :disabled="checkOneProduct"
            >
              <option v-for="item in dataProducts" :value="item.id" :key="item.id">
                {{ item.title }}
              </option>
            </select>

            <span
                v-if="!checkOneProduct"
                class="absolute i-ic:twotone-keyboard-arrow-down w-5.5 h-5.5 right-4.5 -z-1 duration-300 z-1 text-[#8C8C8C]"
            ></span>
          </div>
        </div>
        <div class="w-full">
          <label
              for="product_category_popup_request_analysis"
              class="uppercase text-bw-26 text-13px font-semibold block pb-2"
          >
            {{ $t('FIELD_PRODUCT') }}
          </label>
          <div class="relative flex items-center group">
            <select
                id="product_category_popup_request_analysis"
                required
                class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
                v-model="infoForm.product_plan"
            >
              <option v-for="item in dataPlan" :value="item.id" :key="item.id">
                {{ item.title }}
              </option>
            </select>

            <span
                class="absolute i-ic:twotone-keyboard-arrow-down w-5.5 h-5.5 right-4.5 -z-1 duration-300 z-1 text-[#8C8C8C]"
            ></span>
          </div>
        </div>
        <div class="w-full relative">
          <label
              for="call_me_popup_request_analysis"
              class="uppercase text-bw-26 text-13px font-semibold block pb-2"
          >{{ $t('TIME_TO_CONTACT') }}</label
          >
          <div class="relative flex items-center group">
            <input
                type="datetime-local"
                id="call_me_popup_request_analysis"
                v-model="infoForm.call_time"
                class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 text-[#353535]/50 font-medium text-base b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
            />
            <span class="absolute right-4 i-ic:baseline-calendar-today w-4 h-4 text-[#353535]/50"></span>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="w-full flex flex-col items-center justify-center mt-5">
        <GlobalVerifyForm :error="errorChecked" v-model:checked="isChecked" class="mb-3 lg:mb-5"/>
        <button
            class="relative btn-submit-contact flex-1 bg-primary text-white font-semibold uppercase text-sm block px-11.5 rounded-30px mt-auto flex items-center justify-center py-4.5 h-13.5 w-full min-h-13.5"
            :class="successSubmit ? '' : 'pointer-events-none'"
            @click="onSubmit"
        >
          <p v-if="successSubmit">{{ $t('CONTACT_TO_ME') }}</p>
          <div v-else class="absolute loaderForm"></div>
        </button>
      </div>
    </template>
  </v-popup>

  <popup-result-success v-model:visible="isSuccess"></popup-result-success>
  <popup-result-fail v-model:visible="isFail" :title="notificationError" :intro="introError"></popup-result-fail>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import nuxtStorage from 'nuxt-storage'
import PopupResultSuccess from './__PopupResultSuccess.vue'
import PopupResultFail from './__PopupResultFail.vue'

const {t} = useI18n()
const {actionPostAnalysisResult, getProductsAdviceForm} = useProducts()

const emits = defineEmits(['closeOTP'])

const props = defineProps({
  products: {
    type: Array as any
  }
})

const closeOTP = () => {
  isOpen.value = false
}

const successSubmit = ref(true)
const langCode = useRoute().params.langCode
const checkOneProduct = ref(false)
const dataProducts = ref()
const dataPlan = ref()
const isOpen = ref(true)
const notificationError = ref()
const introError = ref()
const isSuccess = ref(false)
const isFail = ref(false)
const now = ref(new Date())
const errorPhone = ref(false)
const errorName = ref(false)
const errorChecked = ref(false)
const isChecked = ref()
const dateTimeDefault = computed(
    () =>
        `${now.value.getFullYear()}-${String(now.value.getMonth() + 1).padStart(2, '0')}-${String(
            now.value.getDate()
        ).padStart(2, '0')}T${String(now.value.getHours()).padStart(2, '0')}:${String(now.value.getMinutes()).padStart(
            2,
            '0'
        )}`
)

const data_input = ref<any>(getStorage('data_analysis', {}) ?? {})
const main_data = ref<any>(data_input.value?.MainData ?? {})
const sub_data = ref<any>(data_input.value?.SubData ?? {})
const data_submit = ref({})
for (const key in main_data.value) {
  if (main_data.value.hasOwnProperty(key)) {
    const value = main_data.value[key]
    if (typeof value !== 'string') {
      data_submit.value[key] = value
    }
  }
}

const convertDate = (inputDateString: any) => {
  const inputDate = new Date(inputDateString)
  const day = inputDate.getUTCDate().toString().padStart(2, '0')
  const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = inputDate.getUTCFullYear()

  const formattedDateString = `${day}-${month}-${year}`
  return formattedDateString
}

const infoForm = ref({
  lang: useRoute().params.langCode,
  name: main_data.value?.fullname || '',
  phone: '',
  product: '',
  product_plan: '',
  call_time: dateTimeDefault.value,
  detail: data_submit.value,
  birthday: convertDate(sub_data.value?.date_of_birth)
})

watch(
    () => infoForm.value.product,
    () => {
      dataProducts.value.forEach((item) => {
        if (item?.id == infoForm.value.product) {
          dataPlan.value = item?.plans
          infoForm.value.product_plan = dataPlan.value[0]?.id
        }
      })
    }
)

const checkName = () => {
  if (infoForm.value?.name) {
    errorName.value = false
  } else {
    errorName.value = true
  }
}

const convertedPhoneNumber = () => {
  if (infoForm.value.phone !== '' && infoForm.value.phone[0] === '0') {
    var convertedPhoneNumber = infoForm.value.phone?.substring(1)
    infoForm.value.phone = '+84' + convertedPhoneNumber
  }
}
const checkPhone = () => {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
  if (infoForm.value.phone) {
    var convertedPhoneNumber = infoForm.value.phone.replace(/^\+84/, '0')
    if (vnf_regex.test(convertedPhoneNumber)) {
      errorPhone.value = false
    } else {
      errorPhone.value = true
    }
  } else {
    errorPhone.value = true
  }
}

const checkChecked = () => {
  if (isChecked.value) {
    errorChecked.value = false
  } else {
    errorChecked.value = true
  }
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

watch(
    () => isOpen.value,
    () => {
      if (!isFail.value && !isSuccess.value) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isSuccess.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded()
  const token = await recaptchaInstance?.executeRecaptcha('login')
  return token
}

const onSubmit = async (): Promise<void> => {
  checkChecked()
  checkName()
  checkPhone()
  if (!errorPhone.value && !errorName.value && !errorChecked.value) {
    const token = await recaptcha()
    successSubmit.value = false
    const resutl_request = await actionPostAnalysisResult(infoForm.value, token)

    if (resutl_request.code == 201) {
      isOpen.value = false
      isSuccess.value = true
      infoForm.value = {
        lang: useRoute().params.langCode,
        name: '',
        phone: '',
        product: dataProducts.value[0] ? dataProducts.value[0]?.id : '',
        product_plan: dataPlan.value[0] ? dataPlan.value[0]?.id : '',
        call_time: dateTimeDefault.value,
        detail: {},
        birthday: ''
      }
      nuxtStorage.localStorage.removeItem('data_analysis')
    } else {
      isOpen.value = false
      isFail.value = true
      notificationError.value = t('THE_SERVER_IS_HAVING_PROBLEMS')
      introError.value = t('SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL2')
    }

    successSubmit.value = true
  }
}
onMounted(async () => {
  setInterval(() => {
    now.value = new Date()
    // infoForm.value.call_time = dateTimeDefault.value
  }, 1000)

  if (props.products && props.products.length > 0) {
    dataProducts.value = props.products
    if (props.products.length > 1) {
      checkOneProduct.value = false
    } else {
      checkOneProduct.value = true
    }
  } else {
    dataProducts.value = await getProductsAdviceForm(langCode)
  }
  infoForm.value.product = dataProducts.value[0]?.id
  dataProducts.value.forEach((item) => {
    if (item?.id == infoForm.value.product) {
      dataPlan.value = item?.plans
      infoForm.value.product_plan = dataPlan.value[0]?.id
    }
  })
  infoForm.value.product_plan = dataPlan.value[0]?.id
})
</script>

<style scoped>
.bg-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
