<template>
  <popup v-model:visible="isOpen" @isRestart="isRestart = $event" @isContinue="isContinue = $event"></popup>
  <user-profile v-if="checkShow(1)" :step="step" :isRestart="isRestart" @updateStep="step = $event"></user-profile>

  <income-group v-if="checkShow(2)" :step="step" @updateStep="step = $event"></income-group>

  <protection-needs
      v-if="checkShow(3)"
      :step="step"
      @updateStep="step = $event"
      @submitData="request_data($event)"
  ></protection-needs>

  <recommend v-if="checkShow(4)" :step="step" :data="data_result"></recommend>
  <div class="!md:w-[calc(50%-7px)] hidden"></div>
</template>
<script lang="ts">
export default {
  name: 'start_analysis'
}
</script>

<script setup lang="ts">
import nuxtStorage from 'nuxt-storage'
import Popup from './components/__Popup.vue'
import UserProfile from './components/UserProfile.vue'
import IncomeGroup from './components/IncomeGroup.vue'
import ProtectionNeeds from './components/ProtectionNeeds.vue'
import Recommend from './components/Recommend.vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {getAnalysisGroups} = useAnalysis()

definePageMeta({
  layout: 'analysis'
})

const store = useGetLangCurrent()
const isOpen = ref(false)
const isRestart = ref(false)
const isContinue = ref(false)
const isFinish = ref(false)
const data_analysis = ref<any>({})
const step = ref<number>()
const data_result = ref({})

const checkShow = (index: number) => {
  if (step.value == index) {
    return true
  } else {
    return false
  }
}

const dataRender = await getAnalysisGroups(useRoute().params.langCode).then((response) => response?.analysis_group)

const request_data = (value) => {
  data_analysis.value['step'] = step.value
  data_result.value = value
  // data_protectionNeeds.value = value
  // console.log(`output->value`, data_protectionNeeds.value)
  if (getStorage('data_analysis', false)) {
    nuxtStorage.localStorage.removeItem('data_analysis')
    isFinish.value = true
    isRestart.value = true
  }
}

useDirectusCollectionSeo('analysis_start', {}, useRoute().params.langCode)

watch(
    () => isContinue.value,
    (newValue) => {
      step.value = getStorage('data_analysis', {})?.SubData?.step
      checkShow(step.value)
    }
)

watch(
    () => isRestart.value,
    (newValue) => {
      if (isRestart.value) {
        if (isFinish.value) {
          step.value = 4
        } else {
          step.value = 1
        }
      }
    }
)

onMounted(() => {
  if (
      getStorage('data_analysis', false) &&
      getStorage('data_analysis', false)?.MainData &&
      Object.keys(getStorage('data_analysis', false)?.MainData).length > 0
  ) {
    isOpen.value = true
  }
  step.value = getStorage('data_analysis', {})?.SubData?.step ?? 1

  store.lang_switch = dataRender?.lang_switch
})
</script>

<style scoped>
.box-popup-popupResultOtp > div {
  border-radius: 20px;
  padding: 30px;
}
</style>
