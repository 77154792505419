<template>
  <li class="flex gap-1.5 items-center">
    <span v-if="step > 1" class="w-7 h-1px bg-#DEDEDE" :class="step <= indexActive ? '!bg-primary' : ''"></span>

    <p
        class="flex justify-center items-center w-4 h-4 rounded-full border text-10px font-[550]"
        :class="[
                step == indexActive ? 'border-primary text-primary' : '',
                step < indexActive || indexActive == 4 ? 'bg-primary' : '',
                step < indexActive ? 'border-none' : ''
            ]"
    >
      <span v-if="step < indexActive || indexActive == 4" class="i-custom-checked text-white text-8px"></span>
      <span v-else>{{ step }}</span>
    </p>
    <p
        class="text-13px leading-5"
        :class="[
                step == indexActive || step < indexActive ? 'text-accent-03' : 'text-bw-28',
                step == indexActive ? 'font-[550]' : ''
            ]"
    >
      {{ dataRender?.name }}
    </p>
  </li>

  <template v-if="dataRender.children && dataRender.children.length > 0">
    <item-breadcrumb :step="step + 1" :dataRender="dataRender.children[0]" :indexActive="indexActive">
    </item-breadcrumb>
  </template>
  <slot></slot>
</template>

<script setup lang="ts">
import ItemBreadcrumb from './__ItemBreadcrumb.vue'

const count = ref<Number>(1)
defineProps({
  dataRender: {
    type: Object as any
  },
  step: {
    type: Number as any
  },
  indexActive: {
    type: Number as any
  }
})
</script>

<style></style>
