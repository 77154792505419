<template>
  <div v-if="question?.interface == 'dropdown'" class="flex flex-col relative">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? '!text-error-01 ' : 'text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>
    <div
        v-else
        :class="error ? 'text-error-01 ' : ' text-bw-30'"
        class="text-lg leading-6 font-[550] border-b border-bw-31 pb-2 mb-3"
    >
      {{ question?.content }}
    </div>
    <v-select
        @click="addScrollBar()"
        class="style-select-analysis insurance_fees"
        v-model="data"
        :class="error ? '!text-error-01 error !border-error-01' : ''"
        :placeholder="question?.content + '...'"
        :options="question?.answers"
        label="content"
    ></v-select>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String
})

const emits = defineEmits(['getData'])

const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})

const data = ref(answer.value)
const error = ref(false)

const addScrollBar = (event: any) => {
  document.querySelectorAll('[id^="vs"]').forEach((item) => {
    item.classList.add('custom-scrollbar')
  })
}

const checkValue = () => {
  if (data.value) {
    error.value = false
    let dataSave = {}
    dataSave[props.question?.field_name] = data.value
    saveMainDataLocal(dataSave, false)
    emits('getData', data.value?.id)
  } else {
    error.value = true
  }
}

watch(
    () => data.value,
    (newValue) => {
      if (newValue != null) {
        checkValue()
      }
    }
)
watch(
    () => props.error,
    () => {
      checkValue()
    }
)

watch(
    () => props.answer,
    (newValue) => {
      if (Object.keys(newValue).length < 1) {
        data.value = null
      }
    }
)
</script>

<style>
@import 'vue-select/dist/vue-select.css';

.style-select-analysis .vs__search,
.style-select-analysis .vs-selected {
  @apply !py-0 !text-black  !m-0;
}

.style-select-analysis .vs__search {
  @apply !p-0;
}

.style-select-analysis.v-select {
  @apply px-5 py-3.5 !border border-#CBCBCB text-bw-32 rounded-3 text-sm leading-6 font-medium capitalize h-13.5 flex items-center;
}

.style-select-analysis.v-select.vs--single.vs--searchable,
.style-select-analysis.v-select.vs--multiple {
  @apply !w-full;
}

.style-select-analysis .vs__clear {
  @apply p-2 lg:p-3 transform translate-x-4;
}

.style-select-analysis .vs__search::placeholder {
  @apply text-black/30 !text-sm;
}

.style-select-analysis .vs__dropdown-option,
.style-select-analysis-other .vs__dropdown-option {
  white-space: break-spaces;
  @apply p-3 text-xs md:text-sm;
}

.style-select-analysis .vs__dropdown-menu {
  @apply bg-white bg-shadow mt-1 !lg:mt-2 b-none divide-y divide-y-bw-04;
}

.style-select-analysis .vs-selected-bg {
  @apply bg-primary;
}

.style-select-analysis .vs__dropdown-option--highlight {
  @apply bg-primary;
}

.style-select-analysis .vs__selected {
  @apply font-medium text-sm line-clamp-1 !p-0 !m-0 w-90% flex items-center;
}

.style-select-analysis-other .vs__selected {
  @apply font-semibold text-sm lg:text-base   line-clamp-1 text-white;
}

.style-select-analysis-other .vs__search::placeholder {
  @apply text-[#ffe8df] text-sm lg:text-base;
}

.style-select-analysis-other .vs__open-indicator,
.style-select-analysis-other .vs__clear {
  fill: #ffe8df;
}

.style-select-analysis .vs__actions {
  @apply !p-0 mr-2;
}

.style-select-analysis .vs__clear {
  @apply mr-3;
}

.style-select-analysis .vs__open-indicator,
.style-select-analysis .vs__clear {
  fill: #686868;
}

.style-select-analysis .vs__dropdown-toggle {
  @apply !border-none w-100%;
}

.style-select-analysis .vs__open-indicator {
  @apply transform scale-60 lg:scale-80;
}

.style-select-analysis .vs__clear {
  @apply transform scale-80 lg:scale-100;
}

.style-select-analysis.error .vs__search {
  @apply !placeholder:text-error-01;
}

.style-select-analysis .vs__dropdown-menu {
  @apply !p-0 !rounded-6px;
}

.vs__deselect {
  @apply absolute top-1/2 transform -translate-y-1/2 right-1 hover:bg-gray-300 bg-gray-200 rounded-full w-4 h-4 flex flex-col items-center justify-center;
}
</style>
