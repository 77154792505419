<template>
  <section class="container flex py-10 lg:flex-row flex-col lg:gap-15 xl:gap-24.5 h-full">
    <div class="flex flex-col lg:max-w-100 xl:max-w-133.5 w-full lg:h-full lg:sticky lg:top-35">
      <breadcrumb :indexActive="step" :dataRender="dataRender"></breadcrumb>

      <div v-if="dataRender?.title" v-html="dataRender?.title" class="post-content"></div>

      <div
          class="text-bw-27 text-base leading-7 lg:max-w-117 post-content"
          v-html="dataRender?.description"
      ></div>
      <div class="absolute top-full -left-50 lg:block hidden">
        <div class="w-180 xl:w-220 aspect-260/122">
          <img src="/images/global/analysis2.png" alt="background" class="w-full h-full object-contain"/>
        </div>
        <div class="lg:w-50 xl:w-70 aspect-65/49 absolute top-[20%] left-1/2">
          <img
              src="/images/global/masgotAnalysis.png"
              alt="masgot"
              class="w-full h-full object-contain animationSway"
          />
        </div>
      </div>
    </div>

    <div class="relative absolute bottom-0 left-0 lg:hidden block flex justify-center">
      <div class="w-138 aspect-238/147">
        <img src="/images/global/analysis2.png" alt="background" class="w-full h-full object-contain"/>
      </div>
      <div class="w-35 md:w-40 aspect-65/49 absolute top-[20%] left-1/2">
        <img
            src="/images/global/masgotAnalysis.png"
            alt="masgot"
            class="w-full h-full object-contain animationSway"
        />
      </div>
    </div>

    <div class="h-full flex-1">
      <form
          action=""
          @submit.prevent=""
          class="rounded-15px bg-white p-5 lg:p-10 w-full h-max box-form-analysis"
          style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 12px 0px"
      >
        <div
            class="text-bw-30 text-lg leading-6 font-[550] pb-3 border-b border-bw-31 mb-3"
            v-html="dataRender?.questions_intro"
        ></div>
        <div v-if="questions && questions.length > 0" class="flex gap-y-4 gap-x-3.5 flex-wrap">
          <template v-for="question in questions">
            <input-text
                :question="question"
                :answer="main_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
            />

            <input-radio
                :question="question"
                :answer="main_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
            />

            <input-date
                :question="question"
                :answer="sub_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
            />
            <input-select
                :question="question"
                :answer="sub_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
            />

            <input-radio-special
                :question="question"
                :answer="main_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
                class="mt-5"
            />

            <input-checkbox-special
                :question="question"
                :answer="main_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
                class="mt-5"
            />

            <input-checkbox
                :question="question"
                :answer="main_data"
                :error="error"
                @getData="saveData(question?.field_name, $event)"
                :class="isSize(question?.size)"
            />
          </template>
        </div>
        <div class="h-1px w-full bg-bw-31 mt-5 mb-4.5"></div>

        <div class="flex gap-2 flex-row items-center">
          <nuxt-link
              :to="$t('ROUTER_ANALYSIS')"
              class="rounded-9 overflow-hidden md:border md:border-primary text-primary uppercase font-[550] text-xs p-4 md:py-5 md:px-14 leading-5 md:hover:text-white md:hover:bg-primary duration-300 text-center h-13.5 flex items-center"
          >
            {{ $t('BACK') }}
          </nuxt-link>
          <button-primary
              :title="$t('NEXT')"
              class="flex-1 rounded-full !font-[550] !text-xs h-13.5 flex items-center"
              padding=" py-3 md:py-5 px-14"
              @click="nextPage()"
          ></button-primary>
        </div>
        <div class="w-full text-red-700 font-semibold text-center mt-5" v-if="error">{{ error }}</div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'userProfile'
}
</script>

<script setup lang="ts">
import Breadcrumb from './__Breadcrumb.vue'
import InputText from '../commons/inputText.vue'
import InputRadio from '../commons/inputRadio.vue'
import InputDate from '../commons/inputDate.vue'
import InputSelect from '../commons/inputSelect.vue'
import InputRadioSpecial from '../commons/inputRadioSpecial.vue'
import InputCheckboxSpecial from '../commons/inputCheckboxSpecial.vue'
import InputCheckbox from '../commons/inputCheckbox.vue'

const {t} = useI18n()
const {getAnalysisGroups} = useAnalysis()

const props = defineProps({
  step: {
    type: Number as any
  },
  isRestart: {
    type: Boolean
  }
})
const emits = defineEmits(['updateStep'])

const dataRender = await getAnalysisGroups(useRoute().params.langCode).then((response) => response?.analysis_group)
const questions = computed(() => {
  return dataRender?.questions
})

const data_input = ref<any>(getStorage('data_analysis', {}) ?? {})
const main_data = ref<any>(data_input.value?.MainData ?? {})
const sub_data = ref<any>(
    data_input.value?.SubData && Object.keys(data_input.value?.MainData).length > 0 ? data_input.value?.SubData : {}
)
const error = ref()

const saveData = (key: any, value: any) => {
  main_data.value[key] = value
}

const nextPage = () => {
  if (checkTheTransitionCondition(main_data.value, questions.value)) {
    error.value = ''
    saveMainDataLocal(main_data.value, true)
    saveMainDataLocal({step: props.step}, false)
    emits('updateStep', props.step + 1)
  } else {
    error.value = t('NOT_ENOUGH_INFORMATION')
    let boxForm = document.querySelector('.box-form-analysis')
    if (boxForm) {
      boxForm.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }
}

watch(
    () => props.isRestart,
    () => {
      data_input.value = {}
      main_data.value = {}
      sub_data.value = {}
    }
)
</script>

<style>
.bg-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.nested-enter-active,
.nested-leave-active {
  transition: all 0.25s ease;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
