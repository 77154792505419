<template>
  <div v-if="question?.interface == 'date'" class="flex flex-col">
    <div
        v-if="question?.title_size === 'small'"
        :class="error ? 'text-error-01 ' : ' text-bw-26'"
        class="leading-6 text-sm mb-2 font-semibold"
    >
      {{ question?.content }}
    </div>

    <div
        v-else
        class="text-bw-30 text-lg font-[550] leading-6 pb-2 mb-3 border-b border-bw-31"
        :class="error ? '!text-error-01' : ''"
    >
      {{ question?.content }}
    </div>
    <div class="relative flex items-center group w-full">
      <GlobalDateTimePicker
          :enable-time-picker="false"
          :min-date="minDate"
          :max-date="new Date()"
          v-model="data"
          :class="error ? 'text-error-01 border-error-01 error' : 'border-#CBCBCB text-bw-32'"
          class="px-5 py-3.5 border rounded-3 text-sm leading-6 font-medium w-full max-h-13.5 inputDate"
      >
      </GlobalDateTimePicker>
    </div>
  </div>
</template>
<script setup lang="ts">
import {ref} from 'vue'

const props = defineProps({
  question: Object,
  answer: Object as any,
  error: String
})
const emits = defineEmits(['getData'])

const currentDate = new Date()
const minDate = new Date(currentDate)
minDate.setFullYear(currentDate.getFullYear() - 60)

const answer = computed(() => {
  if (props?.answer) {
    return props?.answer[props?.question?.field_name]
  }
  return null
})

const data = ref(answer.value)
const error = ref(false)
const idAnswer = ref()

const handleAge = () => {
  let id_question = 0
  id_question = props.question?.id
  let dayNow = new Date()
  let yearbirthSub = new Date(data.value)
  let ageNow = dayNow.getFullYear() - yearbirthSub.getFullYear()
  if (+ageNow < 20) {
    idAnswer.value = 415
  } else {
    if (+ageNow <= 25) {
      idAnswer.value = 411
    } else if (+ageNow > 25 && +ageNow <= 35) {
      idAnswer.value = 412
    } else if (+ageNow > 35 && +ageNow <= 45) {
      idAnswer.value = 413
    } else if (+ageNow > 45 && +ageNow <= 50) {
      idAnswer.value = 414
    } else {
      idAnswer.value = 416
    }
  }
}

const checkValue = () => {
  if (data.value) {
    error.value = false
    handleAge()
    let dataSave = {}
    dataSave[props.question?.field_name] = data.value
    saveMainDataLocal(dataSave, false)
    emits('getData', idAnswer.value)
  } else {
    error.value = true
  }
}

watch(
    () => data.value,
    (newValue) => {
      if (newValue != null) {
        checkValue()
      }
    }
)
watch(
    () => props.error,
    () => {
      checkValue()
    }
)

watch(
    () => props.answer,
    (newValue) => {
      if (Object.keys(newValue).length < 1) {
        data.value = null
      }
    }
)
</script>

<style>
.dp__pointer {
  @apply border-none py-0 text-sm;
}

.dp__icon {
  @apply p-0;
}

.inputDate.error input.dp__pointer::placeholder {
  @apply !text-error-01;
}
</style>
